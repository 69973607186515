.preloader_animate {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.preloader_animate img {
    width: 30rem;
}

@media screen and (max-width: 450px) and (orientation: portrait) {
    .preloader_animate img {
        width: 20rem;
    }
}

@media screen and (max-width: 914px) and (orientation: landscape) {
    .preloader_animate img {
        width: 20rem;
    }
}

.subtitle {
    font-size: 1.5rem;

    position: absolute;
    bottom: 3rem;
}