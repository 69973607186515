.main_logo {
    position: relative;
    padding-top: 30px;
    padding-bottom: 20px;
    width: 220px;
    z-index: 1001;
}

.logo_none{
    position: absolute;
    display: none;
}