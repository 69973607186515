.others_books_container {
  /* height: 250px; */
  padding: 10px 0 0 0;
  display: inline-flex;
  opacity: 0;
}

.others_books_container_overflow {
  width: 100%;
  max-width: 1000px;

  margin: 0px auto;
  margin-bottom: -30rem;
}

.others_books_slide_wrapper {
  filter: drop-shadow(0px 0px 0.5rem rgba(0, 0, 0, 0.2));
}

.others_books_slide_wrapper:nth-child(odd) {
  padding-left: 0.8rem;
}

.others_books_slide_wrapper:nth-child(even) {
  padding-right: 0.8rem;
}

.others_books_content {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

@media (min-width: 650px) {
  .others_books_content {
    justify-content: center;
  }
}

.others_books_container .slider {
  width: calc(var(--number-body-default-width) - 80px) !important;
}

.others_books_container .slider button {
  font-family: "Intro Bold" !important;
  font-size: 12px !important;
  color: var(--color-text-primary) !important;
}

.others_books_container .slider .slider-frame {
  overflow: visible!important;
}

.others_books_slide {
  width: 170px;
  height: 170px;
  margin: 20px 0px;
  display: inline-flex;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  background-color: var(--color-secondary);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transition-property: opacity box-shadow;
  -webkit-transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-property: opacity box-shadow;
  font-size: 14px;
}

@media (min-width: 650px) {
  .others_books_slide {
    margin: 20px;
  }
}

.others_books_container p {
  font-size: 16px;
}

.slide-visible .others_books_slide {
  box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition-property: opacity box-shadow;
  -webkit-transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-property: opacity box-shadow;
  opacity: 1;
}

.slider-control-bottomcenter {
  display: none;
}

.slider-control-centerleft {
  display: none;
}

.slider-control-centerright {
  display: none;
}

.slider-control-bottomright {
  bottom: 56px !important;
}

.slider-control-bottomleft {
  bottom: 56px !important;
}

.load_more {
  font-family: "Intro Bold";
  cursor: pointer;
  font-size: 17px;
  color: var(--color-white);
  background-color: transparent;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 12px;
  padding-bottom: 8px;
  width: 200px;
  border-color: var(--color-white);
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  text-align: center;

  margin-top: 3rem;
}

.others_books_nav_buttons {
  cursor: pointer;
  font-family: "Intro Bold";
  font-size: 18px;
  padding: 10px;
}

div:focus {
  outline: none;
}

#availability {
  font-size: 10px;
  margin-block-start: -8px;
}

#availabilityDate {
  font-size: 10px;
  margin-block-start: -8px;
}