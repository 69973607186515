.info_small_buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: var(--color-white);
}

.info_small_buttons_container p {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    margin-block-end: 5px;
    cursor: pointer;
}