.fale_conosco_container  {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
}

.fale_conosco_container input  {
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    color:black;
    background-color:white;
    width: 200px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-color: #6D6E71;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
}

.fale_conosco_container textarea  {
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    color: black;
    background-color:white;
    width: 200px;
    height: 150px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-color: #6D6E71;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
}

.fale_conosco_container span {
    color:red;
    font-size: 9px;
}