
.box {
    background-color: rgb(255,255,255,.9);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
}

.container {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.container img {
    opacity: 1
}

.container span {
    color: black;
    font-size: 12px;
    display: flow-root;
}

@media screen and (min-width: 1025px) and (orientation: landscape) {
    .box {
        display: none;
    }
}
