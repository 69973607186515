/* *{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
} */

:root {
  --color-green: #009783;
}

.reader_container {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: black;
  flex-direction: column;
  position: fixed;
  top: 0;
}

.reader_list {
  list-style-type: none;
}

.reader_wrapper {
  position: fixed;
  width: 100vw;
}

.reader_background_header {
  height: 48px;
  /* background-color: #73c6bb; */
  justify-content: center;
  display: flex;
}

/* @media screen and (max-width: 1024px) {
  .reader_background_header {
    background-color: #009783;
  }
} */

.reader_header {
  display: flex;
  width: 100vw;
  /* width: 1024px; */
  justify-content: flex-end;
  /* background-color: #009783; */
}

.reader_subtext_title {
  display: block;
  font-family: "Intro Regular";
  /* color: #8bffef; */
  line-height: 1.3em;
  font-size: 15px;
  text-align: left;
  margin: 15px 0 15px 15px;
  font-weight: 600;
}

.reader_subtext_book {
  display: block;
  font-family: "Intro Regular";
  color: "#FFFFFF";
  line-height: 1.3em;
  font-size: 15px;
  text-align: left;
  margin: 15px;
  font-weight: 600;
  flex-grow: 1;
  overflow: hidden;
}

.reader_subtext_book_single {
  display: none;
  font-family: "Intro Regular";
  color: "#FFFFFF";
  line-height: 1.3em;
  font-size: 15px;
  text-align: left;
  margin: 15px;
  font-weight: 600;
  flex-grow: 1;
  overflow: hidden;
}

.render_pages_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 14px;
}

.reader_pages_button {
  width: 48px;
  height: 48px;
  border-width: 0;
  background-color: #ffffff55;
  cursor: pointer;
  border-radius: 25px;
}

.render_pages_audioButtons {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.reader_pages_close {
  width: 48;
  height: 48;
  border-width: 0;
  background-color: #FFFFFF55;
  position: fixed;
  top: 14px;
  right: 20px;
}

.reader_pages_fullscreen {
  position: fixed;
  bottom: 15px;
  right: 15px;

  display: flex;
  align-items: center;
  width: 35px;
  height: 35px;
  border-width: 0;
  background-color: #ffffff55;
  cursor: pointer;
}

.reader_flip_pages_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flip_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.reader_wrapper_controller_right {
  background-color: #FFFFFF99;
  position: fixed;
  right: 0;
  border-width: 0;
  /* margin-top: 48px; */
}

.reader_wrapper_controller_left {
  background-color: #FFFFFF99;
  position: fixed;
  left: 0;
  border-width: 0;
  /* border-width: 0; */
  /* margin-top: 48px; */
}

.flip_pages_wrapper {
  /* margin-top: 48px; */
  /* width: 1024px; */
  /* height: calc(100vh - 48px); */
  perspective: 2600px;
  /* -ms-user-select: none; */
  /* -moz-user-select: none; */
  /* -webkit-user-select: none; */
  /* user-select: none; */
}

/* @media screen and (max-width: 960px) {
  .reader_pages_close {
    top: 0px;
  }
  .reader_subtext_title {
    display: none;
  }
  .reader_subtext_book {
    display: none;
  }
  .reader_subtext_book_single {
    display: block;
  }
} */
/* @media screen and (max-width: 550px) {
    .reader_wrapper_controller {
      display: none;
    }
} */
