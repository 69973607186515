.footer_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.footer_box {
    width: 115px;
    height: 115px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.footer_logo {
    width: 100px;
    cursor: pointer;
}

.footer_container p {
    margin-block-start: 2em;
    font-size: 12px;
    color: var(--color-footer-primary);
    line-height: 14px;
}