.App {
  /* align-items: center; */
  text-align: center;
  background-color: var(--color-complementary-two);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  overflow: hidden;
}


#FloatButton {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  background: transparent;
  box-shadow: none;
  border: 0;
  padding: 0;
  cursor: pointer;

  z-index: 100;

  @media (max-width: 768px) {
    right: 1rem;
    bottom: 1rem;
  }
}

#FloatButton > img {
  width: 50px;
  height: 50px;
}

#game-manager-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

#game_manager_info_button {
  margin-top: auto;
}

.game_manager_footer {
  margin-top: 3rem;
}

.game-manager-wrapper {
  width: 100vw;
  position: relative;
  background-image: url('./../../assets/images/games-background.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: 1024px;
  padding-top: 16rem;
  overflow-y: scroll;
}

.game-manager {
  width: 100%;
  height: 45rem;
  border: 0;

  transition: height 0.5s linear;
}

#ladingpage {
  transition: opacity 0.4s ease-in-out;
}

.passatempos-banner-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 6rem;
}

.passatempos-banner {
  width: 475.94px;
  height: 362.52px;

  margin-right: 3.2rem;
}
